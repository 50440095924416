import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  style: {
    "display": "block"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_table_list = _resolveComponent("table-list");

  var _component_layout = _resolveComponent("layout");

  return _openBlock(), _createBlock(_component_layout, null, {
    left: _withCtx(function () {
      return [];
    }),
    right: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createVNode(_component_table_list, {
        ref: "table",
        onTableheight: _ctx.tableheight,
        "group-id": _ctx.groupId,
        groupList: _ctx.groupList,
        "page-index": _ctx.pageIndex,
        onGetNewGroups: _ctx.newGroups
      }, null, 8, ["onTableheight", "group-id", "groupList", "page-index", "onGetNewGroups"])])];
    }),
    _: 1
  });
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_table_list = _resolveComponent("table-list");

  var _component_Search = _resolveComponent("Search");

  var _component_layout = _resolveComponent("layout");

  return _openBlock(), _createBlock(_component_layout, null, {
    left: _withCtx(function () {
      return [];
    }),
    right: _withCtx(function () {
      return [_createVNode(_component_table_list, {
        ref: "table",
        "group-id": _ctx.groupId,
        groupList: _ctx.groupList,
        onTableheight: _ctx.tableheight
      }, null, 8, ["group-id", "groupList", "onTableheight"])];
    }),
    default: _withCtx(function () {
      return [_createVNode(_component_Search, {
        groupList: _ctx.groupList
      }, null, 8, ["groupList"])];
    }),
    _: 1
  });
}
import _objectSpread from "/root/workspace/survey-java-web_GWKp/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { defineComponent, ref } from "vue"; // import { SearchOutlined } from '@ant-design/icons-vue';

import ScreenBox from "./ScreenBox";
import { useStore } from "vuex";
import modalMod from "./modalMod";
export default defineComponent({
  components: {
    ScreenBox: ScreenBox,
    // SearchOutlined,
    modalMod: modalMod
  },
  setup: function setup(props, context) {
    var store = useStore();
    var title = ref("");
    var visible = ref(false);
    var searchForm = ref({});

    function update(val) {
      searchForm.value = val;
      context.emit("update", _objectSpread(_objectSpread({}, val), {}, {
        project_name: title
      }));
      visible.value = false;
    }

    function onSearch() {
      context.emit("update", _objectSpread(_objectSpread({}, searchForm.value), {}, {
        project_name: title
      }));
    } // 修改知情同意书


    var modalModRef = ref(null); // 知情同意书

    var ediModifyt = function ediModifyt() {
      modalModRef.value.invitationVis = true;
      modalModRef.value.getTeamSettingInfo();
    };

    return {
      update: update,
      onSearch: onSearch,
      searchForm: searchForm,
      title: title,
      visible: visible,
      store: store,
      modalModRef: modalModRef,
      ediModifyt: ediModifyt
    };
  }
});
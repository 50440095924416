import AnswerApi from "../../api";
import { computed, getCurrentInstance, defineComponent, inject, provide, ref } from "vue";
import { message } from "ant-design-vue";
import PfePagination from "@/components/PfePagination.vue";
import ProgressBar from "../ProgressBar.vue";
import Question from "../../questions/Question.vue";
import QFirst from "../../questions/QFirst.vue";
import QLast from "../../questions/QLast.vue";
import QRadio from "../../questions/QRadio.vue";
import QCheckbox from "../../questions/QCheckbox.vue";
import QCascader from "../../questions/QCascader.vue";
import QInput from "../../questions/QInput.vue";
import QRate from "../../questions/QRate.vue";
import QImgText from "../../questions/QImgText.vue";
import QDate from "../../questions/QDate.vue";
import MatrixInput from "../../questions/QMatrix/MatrixInput.vue";
import MatrixRadio from "../../questions/QMatrix/MatrixRadio.vue";
import MatrixCheck from "../../questions/QMatrix/MatrixCheck.vue";
import MatrixRate from "../../questions/QMatrix/MatrixRate.vue";
import QImgShow from "../../questions/QImgShow.vue";
import QImgRadio from "../../questions/QImgRadio.vue";
import QImgCheck from "../../questions/QImgCheck.vue";
import QClass from "../../questions/QClass.vue";
import QSort from "../../questions/QSort.vue";
import QSum from "../../questions/QSum.vue";
import QUpload from "../../questions/QUpload.vue";
import QMap from "../../questions/QMap.vue";
import QPhone from "../../questions/QPhone.vue";
import QPassword from "../../questions/QPassword.vue";
import QSign from "../../questions/QSign.vue";
import QConsent from "../../questions/QConsent.vue";
import QHeat from "../../questions/QHeat.vue";
import QHeatLike from "../../questions/QHeatLike.vue";
import QPSM from "../../questions/high/QPSM.vue";
import QKANO from "../../questions/high/QKANO.vue";
import QBPTO from "../../questions/high/QBPTO.vue";
import QMXD from "../../questions/high/QMXD.vue";

export default defineComponent({
  components: {
    PfePagination,
    ProgressBar,
    Question,
    QFirst,
    QLast,
    QRadio,
    QCheckbox,
    QInput,
    QCascader,
    QRate,
    QImgText,
    QDate,
    MatrixInput,
    MatrixRadio,
    MatrixCheck,
    MatrixRate,
    QImgShow,
    QImgRadio,
    QImgCheck,
    QClass,
    QSort,
    QSum,
    QUpload,
    QMap,
    QPhone,
    QPassword,
    QSign,
    QConsent,
    QHeat,
    QHeatLike,
    QPSM,
    QKANO,
    QBPTO,
    QMXD,
  },
  props: {
    // 是否答题模式
    isAnswer: {
      type: Boolean,
      default: false,
    },
    isTemplate: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const page = ref(0); // 当前页数
    const questionsData = inject("questionsData"); // 问卷数据
    const startAnswerTime = new Date().getTime(); // 开始答题时间戳
    const { proxy } = getCurrentInstance();
    const loading = ref(false);
    const scrollbar = ref(null);

    // 主题样式
    const styleInfo = computed(() => questionsData.value.survey?.style || {});
    // 主题颜色
    const themeColor = computed(() => ({
      stemColor: styleInfo.value.stem_color,
      answerColor: styleInfo.value.answer_color,
      buttonColor: styleInfo.value.button_color,
      buttonTextColor: styleInfo.value.button_text_color,
    }));
    provide("themeColor", themeColor);
    // 分页
    const pages = computed(() => questionsData.value.answer?.pages || []);
    // 当前页问卷
    const questions = computed(() => {
      const currentPages = pages.value[page.value - 1] || [];
      return (questionsData.value.questions || []).filter((quetion) =>
        currentPages.find((index) => quetion.question_index === index)
      );
    });
    // 是否显示分页器
    const showPage = computed(() => {
      return [104, 105, 201].includes(questions.value[0]?.question_type) ? false : true;
    });
    // 答题
    async function answer(callback) {
      if ((questions.value.length || !questionsData.value.questions.length) && props.isAnswer) {
        // 表单验证(当前页)
        const errors = questions.value.filter((question) => {
          const { config, answer, question_type: questionType } = question;
          let isError = false;
          if (config.is_required && !answer) {
            isError = true;
            if (questionType === 10) {
              // 矩阵多选题
              question.error = `每行最少选${config.min_select}个`;
            } else if (questionType === 15) {
              // 分类题
              question.error = "部分选项未归类";
            } else if (!question.error) {
              question.error = "这是一道必答题";
            }
          } else if (
            answer &&
            questionType === 1 &&
            Object.keys(answer).findIndex((value) => !answer[value]) !== -1
          ) {
            // 单选题
            isError = true;
            question.error = `请输入`;
          } else if (answer && questionType === 2) {
            // 多选题
            if (Object.keys(answer).length < config.min_select) {
              let options = [];
              question.list.forEach((list) => {
                options = [...options, ...list.options];
              });
              const index = options.findIndex(
                (option) => option.option_key === Object.keys(answer)[0] && option.is_remove_other
              );
              if (index === -1) {
                isError = true;
                question.error = `最少选择${config.min_select}个`;
              } else {
                question.error = "";
              }
            } else if (Object.keys(answer).findIndex((value) => !answer[value]) !== -1) {
              isError = true;
              question.error = `请输入`;
            } else {
              question.error = "";
            }
          } else if (
            answer &&
            questionType === 14 &&
            Object.keys(answer).length < config.min_select
          ) {
            // 图片多选题
            isError = true;
            question.error = `最少选择${config.min_select}个`;
          } else if (answer && questionType === 17) {
            // 恒定总和题
            let sum = 0;
            Object.keys(answer).forEach((key) => {
              sum += answer[key] * 1;
            });
            if (sum === config.total) {
              question.error = "";
            } else {
              isError = true;
              question.error = `请让总和等于${config.total}`;
            }
          } else if (answer && questionType === 18 && answer.length < config.min_number) {
            // 文件上传题
            isError = true;
            question.error = `最少上传${config.min_number}个文件`;
          } else if (answer && questionType === 4) {
            question.error = "";
            // 填空题
            const { value } = answer;
            switch (config.text_type) {
              case 3: // 字母
                isError = !/^[a-zA-Z]+$/.test(value);
                question.error = isError ? "请输入字母" : "";
                break;
              case 4: // 中文
                isError =
                  !/^(?:[\u3400-\u4DB5\u4E00-\u9FEA\uFA0E\uFA0F\uFA11\uFA13\uFA14\uFA1F\uFA21\uFA23\uFA24\uFA27-\uFA29]|[\uD840-\uD868\uD86A-\uD86C\uD86F-\uD872\uD874-\uD879][\uDC00-\uDFFF]|\uD869[\uDC00-\uDED6\uDF00-\uDFFF]|\uD86D[\uDC00-\uDF34\uDF40-\uDFFF]|\uD86E[\uDC00-\uDC1D\uDC20-\uDFFF]|\uD873[\uDC00-\uDEA1\uDEB0-\uDFFF]|\uD87A[\uDC00-\uDFE0])+$/.test(
                    value
                  );
                question.error = isError ? "请输入中文" : "";
                break;
              case 5: // 邮箱
                isError = !/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(
                  value
                );
                question.error = isError ? "请输入正确的email" : "";
                break;
              case 6: // 手机号
                isError = !/^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(value);
                question.error = isError ? "请输入正确的手机号" : "";
                break;
              case 7: // 身份证号
                isError =
                  !/^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/.test(
                    value
                  );
                question.error = isError ? "请输入正确的身份证号" : "";
                break;
              default:
                break;
            }
            if (!isError && value.length < config.min && ![1, 2].includes(config.text_type)) {
              isError = true;
              question.error = `请输入大于${config.min}个字符`;
            }
          } else if (answer && questionType === 8) {
            // 矩阵填空题
            question.error = "";
            Object.keys(answer).forEach((key) => {
              const value = answer[key];
              switch (config.text_type) {
                case 3: // 字母
                  if (!/^[a-zA-Z]+$/.test(value)) question.error = "请输入字母";
                  break;
                case 4: // 中文
                  if (
                    !/^(?:[\u3400-\u4DB5\u4E00-\u9FEA\uFA0E\uFA0F\uFA11\uFA13\uFA14\uFA1F\uFA21\uFA23\uFA24\uFA27-\uFA29]|[\uD840-\uD868\uD86A-\uD86C\uD86F-\uD872\uD874-\uD879][\uDC00-\uDFFF]|\uD869[\uDC00-\uDED6\uDF00-\uDFFF]|\uD86D[\uDC00-\uDF34\uDF40-\uDFFF]|\uD86E[\uDC00-\uDC1D\uDC20-\uDFFF]|\uD873[\uDC00-\uDEA1\uDEB0-\uDFFF]|\uD87A[\uDC00-\uDFE0])+$/.test(
                      value
                    )
                  )
                    question.error = "请输入中文";
                  break;
                case 5: // 邮箱
                  if (!/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(value))
                    question.error = "请输入正确的email";
                  break;
                case 6: // 手机号
                  if (!/^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(value))
                    question.error = "请输入正确的手机号";
                  break;
                case 7: // 身份证号
                  if (
                    !/^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/.test(
                      value
                    )
                  )
                    question.error = "请输入正确的身份证号";
                  break;
                default:
                  break;
              }
              if (
                !question.error &&
                value.length < config.min &&
                ![1, 2].includes(config.text_type)
              ) {
                question.error = `请输入大于${config.min}个字符`;
              }
            });
            if (question.error) isError = true;
          } else {
            question.error = "";
          }
          return isError;
        });
        if (!errors.length) {
          // 表单验证通过，开始答题
          const questionsAnswer = questions.value.map((question) => ({
            question_index: question.question_index,
            answer: question.answer || {},
          }));
          loading.value = true;
          try {
            const { data } = await AnswerApi.answer({
              id: proxy.$route.query.sn,
              data: {
                answer: JSON.stringify(questionsAnswer),
              },
            });
            // 更新答案
            updateAnswer(data.answer_info_autofill);
            // 更新分页数组
            questionsData.value.answer.pages = data.pages;
            // 更新问卷状态
            if (data.action.code === 20014) {
              const timer = setTimeout(() => {
                const url = data.action.msg;
                toUrl(url);
                clearTimeout(timer);
              });
            }
            // 判断抽奖
            if (data.action.lottery) {
              const endAnswerTime = new Date().getTime();
              const answerTime = endAnswerTime - startAnswerTime;
              proxy.$router.push({
                path: "/luck",
                query: {
                  user_id: proxy.$route.query.sn,
                  id: questionsData.value.answer.sn,
                  time_num: parseInt(answerTime / 1000),
                },
              });
            }
            // 选项隐藏
            hideOptions(data.hide_options);
            // 更新action
            questionsData.value.action = data.action;
            callback(data.jump_to);
            // 写入关联选项缓存
            if (props.isAnswer) {
              if (data.action.code === 20010) {
                const questionsCache = JSON.parse(localStorage.getItem(questionsCache)) || {};
                questionsCache[proxy.$route.query.sn] = questionsData.value.questions;
                localStorage.setItem("questionsCache", JSON.stringify(questionsCache));
              } else {
                const questionsCache = JSON.parse(localStorage.getItem("questionsCache"));
                if (questionsCache?.[proxy.$route.query.sn]) {
                  delete questionsCache[proxy.$route.query.sn];
                  localStorage.setItem("questionsCache", JSON.stringify(questionsCache));
                }
              }
            }
          } catch (error) {
            console.log(error);
          }
          loading.value = false;
        } else {
          const { error, title } = errors[0];
          message.error(`${title}：${error}`);
        }
      } else {
        callback();
      }
    }

    // 上一页
    function previous() {
      page.value -= 1;
      scrollbar.value.scrollTop = 0;
    }

    // 下一页
    async function next() {
      answer((jumpPage) => {
        if (jumpPage) {
          page.value = jumpPage.question_page;
        } else {
          page.value += 1;
        }
        scrollbar.value.scrollTop = 0;
      });
    }

    // 选项隐藏
    function hideOptions(hide) {
      const questionIndex = hide?.question_index;
      if (questionIndex) {
        const qustion = questionsData.value.questions.find(
          (qustion) => qustion.question_index === questionIndex
        );
        qustion.hideOptions = hide.option_key || [];
      }
    }

    // 关联引用
    function onRelation(
      { options, value, list },
      { question_type, question_index, related, answer }
    ) {
      // 关联
      related.forEach((relationItem) => {
        let relationOptions = [];
        if (question_type === 9 || question_type === 10) {
          // 矩阵选择
          list.forEach((item) => {
            if (item.type === relationItem.cite_type) {
              relationOptions = [...relationOptions, ...item.options];
            }
            if (relationItem.relation_type === 1) {
              relationOptions = relationOptions.filter((option) =>
                question_type === 9 ? option.value : option.value?.length
              );
            } else if (relationItem.relation_type === 2) {
              relationOptions = relationOptions.filter((option) =>
                question_type === 9 ? !option.value : !option.value?.length
              );
            }
          });
        } else if (question_type === 11) {
          // 矩阵打分
          list.forEach((item) => {
            if (item.type === relationItem.cite_type) {
              relationOptions = [...relationOptions, ...item.options];
            }
          });
        } else if (question_type === 25 || question_type === 26) {
          // 热区题
          relationOptions = options.filter((option) => {
            if (relationItem.relation_type === 1) {
              return option.status === 1;
            } else if (relationItem.relation_type === 2) {
              return option.status === 2;
            } else if (relationItem.relation_type === 3) {
              return !option.status;
            }
            return true;
          });
        } else if (question_type === 105) {
          // MXD
          options.forEach((currentOptions) => {
            currentOptions.forEach((option) => {
              const index = relationOptions.findIndex(
                (relationOption) => relationOption.option_key === option.option_key
              );
              if (index === -1) {
                // 全部项
                if (relationItem.relation_type === 0) {
                  return relationOptions.push(option);
                }
                // 高相关
                if (relationItem.relation_type === 3) {
                  return option.value === "b" && relationOptions.push(option);
                }
                // 不相关
                if (relationItem.relation_type === 4) {
                  return option.value === "w" && relationOptions.push(option);
                }
              }
            });
          });
        } else if (relationItem.relation_type === 0) {
          // 全部选项
          relationOptions = options;
        } else {
          // 过滤选中/未选中选项
          relationOptions = options.filter((option) => {
            if (question_type === 1) {
              // 单选
              if (relationItem.relation_type === 1) return value === option.option_key;
              return value !== option.option_key;
            } else if (question_type === 2) {
              // 多选
              if (relationItem.relation_type === 1) return value.includes(option.option_key);
              return !value.includes(option.option_key);
            }
            return true;
          });
        }
        // 找到关联题
        const question = questionsData.value.questions.find(
          (question) => question.question_index === relationItem.relation_question_index
        );
        // 深拷贝关联选项
        const copyRelationOptions = JSON.parse(JSON.stringify(relationOptions));
        // 更新关联选项key
        copyRelationOptions.forEach((option) => {
          if (option.option_key[0] !== "Q") {
            let letter = "A";
            // 矩阵题行、列
            if (question_type >= 9 && question_type <= 11) {
              letter = relationItem.cite_type === 1 ? "R" : "C";
            }
            option.option_key = `Q${question_index}${letter}${option.option_key}`;
          }
          // 其他项特殊处理
          if (option.is_other && option.value) {
            option.is_other = 0;
            option.option = option.value;
          }
          delete option.value;
          delete option.status;
        });
        // 更新关联题列表
        const relatedList = question.list.find(
          (relatedListItem) => relatedListItem.relation_question_index === question_index
        );
        relatedList.options = answer ? copyRelationOptions : [];
      });
    }

    // 更新答案
    function updateAnswer(auto) {
      if (auto) {
        auto.forEach((autoItem) => {
          const question = questionsData.value.questions.find(
            (question) => question.question_index === autoItem.question_index
          );
          question.answer = JSON.parse(autoItem.answer);
        });
      }
    }

    // 跳转链接
    function toUrl(url) {
      // 判断是否小程序路径
      if (url[0] === "/") {
        // 判断是否在小程序环境
        wx.miniProgram.getEnv(() => {
          wx.miniProgram.redirectTo({ url });
        });
      } else {
        if (url.indexOf("http://") === -1 && url.indexOf("https://") === -1) {
          url = `http://${url}`;
        }
        open(url);
      }
    }

    return {
      page,
      pages,
      loading,
      showPage,
      styleInfo,
      questions,
      scrollbar,
      questionsData,
      answer,
      previous,
      next,
      onRelation,
    };
  },
});

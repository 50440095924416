import Layout from "./components/Layout";
import GroupList from "./fragment/GroupList";
import TableList from "./fragment/TableList";
import Search from "./fragment/TableList";
import { defineComponent, ref, nextTick } from "vue";
export default defineComponent({
  name: "Index",
  components: {
    Layout: Layout,
    TableList: TableList
  },
  setup: function setup(props, context) {
    var groupId = ref(0);
    var table = ref();
    var groupList = ref([]);

    function changeGroup(id) {
      groupId.value = id;
    }

    var rightheight = ref();

    var tableheight = function tableheight(_tableheight) {
      nextTick(function () {
        context.emit('tableheight', table.value.$el.offsetHeight);
        console.log(table.value.$el.offsetHeight, 'index');
      });
      rightheight.value = _tableheight;
    };

    return {
      rightheight: rightheight,
      tableheight: tableheight,
      groupId: groupId,
      changeGroup: changeGroup,
      table: table,
      groupList: groupList,
      Search: Search
    };
  }
});
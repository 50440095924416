import "core-js/modules/es.array.concat.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-b42f3736"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  ref: "scrollbar",
  class: "container"
};
var _hoisted_2 = {
  key: 0,
  class: "progress-bar"
};
var _hoisted_3 = ["src"];
var _hoisted_4 = ["src"];
var _hoisted_5 = {
  class: "footer-page"
};
var _hoisted_6 = {
  key: 3,
  class: "footer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _ctx$questionsData$su, _ctx$questionsData$su2, _ctx$questionsData$su3;

  var _component_progress_bar = _resolveComponent("progress-bar");

  var _component_q_first = _resolveComponent("q-first");

  var _component_q_last = _resolveComponent("q-last");

  var _component_q_radio = _resolveComponent("q-radio");

  var _component_q_checkbox = _resolveComponent("q-checkbox");

  var _component_q_cascader = _resolveComponent("q-cascader");

  var _component_q_input = _resolveComponent("q-input");

  var _component_q_rate = _resolveComponent("q-rate");

  var _component_q_img_text = _resolveComponent("q-img-text");

  var _component_q_date = _resolveComponent("q-date");

  var _component_matrix_input = _resolveComponent("matrix-input");

  var _component_matrix_radio = _resolveComponent("matrix-radio");

  var _component_matrix_check = _resolveComponent("matrix-check");

  var _component_matrix_rate = _resolveComponent("matrix-rate");

  var _component_q_img_show = _resolveComponent("q-img-show");

  var _component_q_img_radio = _resolveComponent("q-img-radio");

  var _component_q_img_check = _resolveComponent("q-img-check");

  var _component_q_class = _resolveComponent("q-class");

  var _component_q_sort = _resolveComponent("q-sort");

  var _component_q_sum = _resolveComponent("q-sum");

  var _component_q_upload = _resolveComponent("q-upload");

  var _component_q_map = _resolveComponent("q-map");

  var _component_q_phone = _resolveComponent("q-phone");

  var _component_q_password = _resolveComponent("q-password");

  var _component_q_sign = _resolveComponent("q-sign");

  var _component_q_consent = _resolveComponent("q-consent");

  var _component_q_heat = _resolveComponent("q-heat");

  var _component_q_heat_like = _resolveComponent("q-heat-like");

  var _component_QPSM = _resolveComponent("QPSM");

  var _component_QKANO = _resolveComponent("QKANO");

  var _component_QBPTO = _resolveComponent("QBPTO");

  var _component_QMXD = _resolveComponent("QMXD");

  var _component_question = _resolveComponent("question");

  var _component_pfe_pagination = _resolveComponent("pfe-pagination");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.styleInfo.is_progress ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_progress_bar, {
    percent: _ctx.page / (_ctx.pages.length + 1) * 100,
    color: _ctx.styleInfo.button_color
  }, null, 8, ["percent", "color"])])) : _createCommentVNode("", true), _ctx.styleInfo.head_img_status && _ctx.styleInfo.head_img_url ? (_openBlock(), _createElementBlock("img", {
    key: 1,
    class: "head-img",
    src: _ctx.styleInfo.head_img_url
  }, null, 8, _hoisted_3)) : _createCommentVNode("", true), _ctx.styleInfo.logo_status && _ctx.styleInfo.logo_url ? (_openBlock(), _createElementBlock("div", {
    key: 2,
    class: "example-logo",
    style: _normalizeStyle([{
      'justify-content': _ctx.styleInfo.logo_site === 1 ? 'flex-start' : _ctx.styleInfo.logo_site === 2 ? 'center' : 'flex-end'
    }, {
      'padding-left': _ctx.styleInfo.logo_site === 1 ? '20px' : ''
    }, {
      'padding-right': _ctx.styleInfo.logo_site === 3 ? '20px' : ''
    }, {
      position: _ctx.styleInfo.head_img_status ? 'absolute' : ''
    }])
  }, [_createElementVNode("img", {
    class: "logo",
    src: _ctx.styleInfo.logo_url,
    alt: "logo"
  }, null, 8, _hoisted_4)], 4)) : _createCommentVNode("", true), _createElementVNode("div", {
    class: "questions",
    style: _normalizeStyle("min-height: ".concat(_ctx.styleInfo.head_img_status ? 'calc(100% - 240px)' : '100%', ";").concat(_ctx.styleInfo.background_status ? "background-color: ".concat(_ctx.styleInfo.background_color, ";background-image: url(").concat(_ctx.styleInfo.background_url, ")") : ''))
  }, [_ctx.page === 0 ? (_openBlock(), _createBlock(_component_q_first, {
    key: 0,
    isMobile: "",
    title: (_ctx$questionsData$su = _ctx.questionsData.survey) === null || _ctx$questionsData$su === void 0 ? void 0 : _ctx$questionsData$su.title,
    desc: (_ctx$questionsData$su2 = _ctx.questionsData.survey) === null || _ctx$questionsData$su2 === void 0 ? void 0 : _ctx$questionsData$su2.introduction,
    isAnswer: _ctx.isAnswer,
    showTitle: _ctx.styleInfo.is_title,
    showDesc: _ctx.styleInfo.is_introduce,
    label: (_ctx$questionsData$su3 = _ctx.questionsData.survey) === null || _ctx$questionsData$su3 === void 0 ? void 0 : _ctx$questionsData$su3.title
  }, null, 8, ["title", "desc", "isAnswer", "showTitle", "showDesc", "label"])) : _ctx.page === _ctx.pages.length + 1 ? (_openBlock(), _createBlock(_component_q_last, {
    key: 1,
    code: _ctx.isAnswer ? _ctx.questionsData.action.code : 20011,
    survey: _ctx.questionsData.survey,
    isAnswer: _ctx.isAnswer
  }, null, 8, ["code", "survey", "isAnswer"])) : (_openBlock(true), _createElementBlock(_Fragment, {
    key: 2
  }, _renderList(_ctx.questions, function (question) {
    return _openBlock(), _createBlock(_component_question, {
      class: "question",
      tip: question.tip,
      stem: question.stem,
      title: question.title,
      error: question.error,
      key: question.question_index,
      questions: _ctx.questionsData.questions,
      questionType: question.question_type,
      questionIndex: question.question_index,
      showTitle: _ctx.styleInfo.is_question_number && true,
      isMobile: "",
      isAnswer: _ctx.isAnswer
    }, {
      default: _withCtx(function () {
        return [question.question_type === 1 ? (_openBlock(), _createBlock(_component_q_radio, {
          key: 0,
          list: question.list,
          config: question.config,
          answer: question.answer,
          "onUpdate:answer": function onUpdateAnswer($event) {
            return question.answer = $event;
          },
          hideOptions: question.hideOptions,
          onChangeAnswer: function onChangeAnswer($event) {
            return _ctx.onRelation($event, question);
          },
          isMobile: ""
        }, null, 8, ["list", "config", "answer", "onUpdate:answer", "hideOptions", "onChangeAnswer"])) : question.question_type === 2 ? (_openBlock(), _createBlock(_component_q_checkbox, {
          key: 1,
          list: question.list,
          config: question.config,
          answer: question.answer,
          "onUpdate:answer": function onUpdateAnswer($event) {
            return question.answer = $event;
          },
          hideOptions: question.hideOptions,
          onChangeAnswer: function onChangeAnswer($event) {
            return _ctx.onRelation($event, question);
          },
          isMobile: ""
        }, null, 8, ["list", "config", "answer", "onUpdate:answer", "hideOptions", "onChangeAnswer"])) : question.question_type === 3 ? (_openBlock(), _createBlock(_component_q_cascader, {
          key: 2,
          list: question.list,
          config: question.config,
          answer: question.answer,
          "onUpdate:answer": function onUpdateAnswer($event) {
            return question.answer = $event;
          },
          isMobile: ""
        }, null, 8, ["list", "config", "answer", "onUpdate:answer"])) : question.question_type === 4 ? (_openBlock(), _createBlock(_component_q_input, {
          key: 3,
          config: question.config,
          answer: question.answer,
          "onUpdate:answer": function onUpdateAnswer($event) {
            return question.answer = $event;
          }
        }, null, 8, ["config", "answer", "onUpdate:answer"])) : question.question_type === 5 ? (_openBlock(), _createBlock(_component_q_rate, {
          key: 4,
          list: question.list,
          config: question.config,
          answer: question.answer,
          "onUpdate:answer": function onUpdateAnswer($event) {
            return question.answer = $event;
          },
          onChangeAnswer: function onChangeAnswer($event) {
            return _ctx.onRelation($event, question);
          },
          isMobile: ""
        }, null, 8, ["list", "config", "answer", "onUpdate:answer", "onChangeAnswer"])) : question.question_type === 6 ? (_openBlock(), _createBlock(_component_q_img_text, {
          key: 5,
          answer: question.answer,
          "onUpdate:answer": function onUpdateAnswer($event) {
            return question.answer = $event;
          }
        }, null, 8, ["answer", "onUpdate:answer"])) : question.question_type === 7 ? (_openBlock(), _createBlock(_component_q_date, {
          key: 6,
          config: question.config,
          answer: question.answer,
          "onUpdate:answer": function onUpdateAnswer($event) {
            return question.answer = $event;
          },
          isMobile: ""
        }, null, 8, ["config", "answer", "onUpdate:answer"])) : question.question_type === 8 ? (_openBlock(), _createBlock(_component_matrix_input, {
          key: 7,
          list: question.list,
          config: question.config,
          answer: question.answer,
          "onUpdate:answer": function onUpdateAnswer($event) {
            return question.answer = $event;
          },
          isMobile: ""
        }, null, 8, ["list", "config", "answer", "onUpdate:answer"])) : question.question_type === 9 ? (_openBlock(), _createBlock(_component_matrix_radio, {
          key: 8,
          list: question.list,
          config: question.config,
          answer: question.answer,
          "onUpdate:answer": function onUpdateAnswer($event) {
            return question.answer = $event;
          },
          onChangeAnswer: function onChangeAnswer($event) {
            return _ctx.onRelation($event, question);
          },
          isMobile: ""
        }, null, 8, ["list", "config", "answer", "onUpdate:answer", "onChangeAnswer"])) : question.question_type === 10 ? (_openBlock(), _createBlock(_component_matrix_check, {
          key: 9,
          list: question.list,
          config: question.config,
          answer: question.answer,
          "onUpdate:answer": function onUpdateAnswer($event) {
            return question.answer = $event;
          },
          onChangeAnswer: function onChangeAnswer($event) {
            return _ctx.onRelation($event, question);
          },
          isMobile: ""
        }, null, 8, ["list", "config", "answer", "onUpdate:answer", "onChangeAnswer"])) : question.question_type === 11 ? (_openBlock(), _createBlock(_component_matrix_rate, {
          key: 10,
          list: question.list,
          config: question.config,
          answer: question.answer,
          "onUpdate:answer": function onUpdateAnswer($event) {
            return question.answer = $event;
          },
          onChangeAnswer: function onChangeAnswer($event) {
            return _ctx.onRelation($event, question);
          },
          isMobile: ""
        }, null, 8, ["list", "config", "answer", "onUpdate:answer", "onChangeAnswer"])) : question.question_type === 12 ? (_openBlock(), _createBlock(_component_q_img_show, {
          key: 11,
          answer: question.answer,
          "onUpdate:answer": function onUpdateAnswer($event) {
            return question.answer = $event;
          },
          list: question.list,
          config: question.config,
          isMobile: ""
        }, null, 8, ["answer", "onUpdate:answer", "list", "config"])) : question.question_type === 13 ? (_openBlock(), _createBlock(_component_q_img_radio, {
          key: 12,
          list: question.list,
          config: question.config,
          answer: question.answer,
          "onUpdate:answer": function onUpdateAnswer($event) {
            return question.answer = $event;
          },
          isMobile: ""
        }, null, 8, ["list", "config", "answer", "onUpdate:answer"])) : question.question_type === 14 ? (_openBlock(), _createBlock(_component_q_img_check, {
          key: 13,
          list: question.list,
          config: question.config,
          answer: question.answer,
          "onUpdate:answer": function onUpdateAnswer($event) {
            return question.answer = $event;
          },
          isMobile: ""
        }, null, 8, ["list", "config", "answer", "onUpdate:answer"])) : question.question_type === 15 ? (_openBlock(), _createBlock(_component_q_class, {
          key: 14,
          list: question.list,
          config: question.config,
          answer: question.answer,
          "onUpdate:answer": function onUpdateAnswer($event) {
            return question.answer = $event;
          },
          onChangeAnswer: function onChangeAnswer($event) {
            return _ctx.onRelation($event, question);
          },
          isMobile: ""
        }, null, 8, ["list", "config", "answer", "onUpdate:answer", "onChangeAnswer"])) : question.question_type === 16 ? (_openBlock(), _createBlock(_component_q_sort, {
          key: 15,
          list: question.list,
          config: question.config,
          answer: question.answer,
          "onUpdate:answer": function onUpdateAnswer($event) {
            return question.answer = $event;
          },
          isMobile: ""
        }, null, 8, ["list", "config", "answer", "onUpdate:answer"])) : question.question_type === 17 ? (_openBlock(), _createBlock(_component_q_sum, {
          key: 16,
          list: question.list,
          config: question.config,
          answer: question.answer,
          "onUpdate:answer": function onUpdateAnswer($event) {
            return question.answer = $event;
          },
          onChangeAnswer: function onChangeAnswer($event) {
            return _ctx.onRelation($event, question);
          },
          isMobile: ""
        }, null, 8, ["list", "config", "answer", "onUpdate:answer", "onChangeAnswer"])) : question.question_type === 18 ? (_openBlock(), _createBlock(_component_q_upload, {
          key: 17,
          config: question.config,
          answer: question.answer,
          "onUpdate:answer": function onUpdateAnswer($event) {
            return question.answer = $event;
          },
          isMobile: ""
        }, null, 8, ["config", "answer", "onUpdate:answer"])) : question.question_type === 19 ? (_openBlock(), _createBlock(_component_q_map, {
          key: 18,
          questionIndex: question.question_index,
          config: question.config,
          answer: question.answer,
          "onUpdate:answer": function onUpdateAnswer($event) {
            return question.answer = $event;
          },
          isMobile: ""
        }, null, 8, ["questionIndex", "config", "answer", "onUpdate:answer"])) : question.question_type === 20 ? (_openBlock(), _createBlock(_component_q_phone, {
          key: 19,
          config: question.config,
          error: question.error,
          "onUpdate:error": function onUpdateError($event) {
            return question.error = $event;
          },
          answer: question.answer,
          "onUpdate:answer": function onUpdateAnswer($event) {
            return question.answer = $event;
          },
          isMobile: ""
        }, null, 8, ["config", "error", "onUpdate:error", "answer", "onUpdate:answer"])) : question.question_type === 21 ? (_openBlock(), _createBlock(_component_q_password, {
          key: 20,
          config: question.config,
          error: question.error,
          "onUpdate:error": function onUpdateError($event) {
            return question.error = $event;
          },
          answer: question.answer,
          "onUpdate:answer": function onUpdateAnswer($event) {
            return question.answer = $event;
          },
          isMobile: ""
        }, null, 8, ["config", "error", "onUpdate:error", "answer", "onUpdate:answer"])) : question.question_type === 22 ? (_openBlock(), _createBlock(_component_q_sign, {
          key: 21,
          config: question.config,
          answer: question.answer,
          "onUpdate:answer": function onUpdateAnswer($event) {
            return question.answer = $event;
          },
          isMobile: ""
        }, null, 8, ["config", "answer", "onUpdate:answer"])) : question.question_type === 23 ? (_openBlock(), _createBlock(_component_q_consent, {
          key: 22,
          title: question.stem,
          config: question.config,
          answer: question.answer,
          "onUpdate:answer": function onUpdateAnswer($event) {
            return question.answer = $event;
          },
          onNext: _ctx.next,
          isMobile: "",
          show: ""
        }, null, 8, ["title", "config", "answer", "onUpdate:answer", "onNext"])) : question.question_type === 25 ? (_openBlock(), _createBlock(_component_q_heat, {
          key: 23,
          list: question.list,
          config: question.config,
          error: question.error,
          "onUpdate:error": function onUpdateError($event) {
            return question.error = $event;
          },
          answer: question.answer,
          "onUpdate:answer": function onUpdateAnswer($event) {
            return question.answer = $event;
          },
          hideOptions: question.hideOptions,
          onChangeAnswer: function onChangeAnswer($event) {
            return _ctx.onRelation($event, question);
          },
          isMobile: ""
        }, null, 8, ["list", "config", "error", "onUpdate:error", "answer", "onUpdate:answer", "hideOptions", "onChangeAnswer"])) : question.question_type === 26 ? (_openBlock(), _createBlock(_component_q_heat_like, {
          key: 24,
          list: question.list,
          config: question.config,
          error: question.error,
          "onUpdate:error": function onUpdateError($event) {
            return question.error = $event;
          },
          answer: question.answer,
          "onUpdate:answer": function onUpdateAnswer($event) {
            return question.answer = $event;
          },
          hideOptions: question.hideOptions,
          onChangeAnswer: function onChangeAnswer($event) {
            return _ctx.onRelation($event, question);
          },
          isMobile: ""
        }, null, 8, ["list", "config", "error", "onUpdate:error", "answer", "onUpdate:answer", "hideOptions", "onChangeAnswer"])) : question.question_type === 101 ? (_openBlock(), _createBlock(_component_QPSM, {
          key: 25,
          stem: question.stem,
          title: question.title,
          error: question.error,
          config: question.config,
          answer: question.answer,
          "onUpdate:answer": function onUpdateAnswer($event) {
            return question.answer = $event;
          },
          isMobile: "",
          isAnswer: _ctx.isAnswer,
          questionIndex: question.question_index,
          label: question.title
        }, null, 8, ["stem", "title", "error", "config", "answer", "onUpdate:answer", "isAnswer", "questionIndex", "label"])) : question.question_type === 102 ? (_openBlock(), _createBlock(_component_QKANO, {
          key: 26,
          error: question.error,
          title: question.stem,
          config: question.config,
          answer: question.answer,
          "onUpdate:answer": function onUpdateAnswer($event) {
            return question.answer = $event;
          },
          isMobile: "",
          isAnswer: _ctx.isAnswer,
          questionIndex: question.question_index,
          label: question.title
        }, null, 8, ["error", "title", "config", "answer", "onUpdate:answer", "isAnswer", "questionIndex", "label"])) : question.question_type === 104 ? (_openBlock(), _createBlock(_component_QBPTO, {
          key: 27,
          error: question.error,
          list: question.list,
          stem: question.stem,
          title: question.title,
          config: question.config,
          answer: question.answer,
          "onUpdate:answer": function onUpdateAnswer($event) {
            return question.answer = $event;
          },
          loading: _ctx.loading,
          onPrevious: _ctx.previous,
          onNext: _ctx.next,
          isMobile: "",
          isAnswer: _ctx.isAnswer,
          questionIndex: question.question_index,
          label: question.title
        }, null, 8, ["error", "list", "stem", "title", "config", "answer", "onUpdate:answer", "loading", "onPrevious", "onNext", "isAnswer", "questionIndex", "label"])) : question.question_type === 105 ? (_openBlock(), _createBlock(_component_QMXD, {
          key: 28,
          error: question.error,
          title: question.stem,
          list: question.list,
          config: question.config,
          version: question.version,
          answer: question.answer,
          "onUpdate:answer": function onUpdateAnswer($event) {
            return question.answer = $event;
          },
          loading: _ctx.loading,
          onPrevious: _ctx.previous,
          onNext: _ctx.next,
          onChangeAnswer: function onChangeAnswer($event) {
            return _ctx.onRelation($event, question);
          },
          isMobile: "",
          isAnswer: _ctx.isAnswer,
          questionIndex: question.question_index,
          label: question.title
        }, null, 8, ["error", "title", "list", "config", "version", "answer", "onUpdate:answer", "loading", "onPrevious", "onNext", "onChangeAnswer", "isAnswer", "questionIndex", "label"])) : _createCommentVNode("", true)];
      }),
      _: 2
    }, 1032, ["tip", "stem", "title", "error", "questions", "questionType", "questionIndex", "showTitle", "isAnswer"]);
  }), 128)), _withDirectives(_createElementVNode("div", _hoisted_5, [_createVNode(_component_pfe_pagination, {
    class: "pagination",
    page: _ctx.page,
    pages: _ctx.pages.length + 1,
    min: 0,
    loading: _ctx.loading,
    showPrevious: _ctx.styleInfo.is_up_button,
    showStart: _ctx.styleInfo.is_start_button,
    startText: _ctx.styleInfo.start_button_text,
    showSubmit: _ctx.styleInfo.is_submit_button,
    submitText: _ctx.styleInfo.submit_button_text,
    buttonTextColor: _ctx.styleInfo.button_text_color,
    buttonColor: _ctx.styleInfo.button_color,
    onPrevious: _ctx.previous,
    onNext: _ctx.next,
    isMobile: ""
  }, null, 8, ["page", "pages", "loading", "showPrevious", "showStart", "startText", "showSubmit", "submitText", "buttonTextColor", "buttonColor", "onPrevious", "onNext"])], 512), [[_vShow, _ctx.showPage]]), _ctx.isAnswer && _ctx.styleInfo.is_yip ? (_openBlock(), _createElementBlock("div", _hoisted_6, "由数字化中心YIP提供支持")) : _createCommentVNode("", true)], 4)], 512);
}
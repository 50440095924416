import "core-js/modules/es.number.constructor.js";
import { ref } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";
import Tinymce from "../../../../components/Tinymce.vue";
export default {
  name: "QuestionTinymceOption",
  components: {
    Tinymce: Tinymce
  },
  props: {
    html: {
      type: String,
      default: ""
    },
    hasAfter: {
      type: [Boolean, Number],
      default: false
    },
    minHeight: {
      type: Number,
      default: 30
    },
    preventKeyDown: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: "请输入"
    },
    defaultStem: {
      type: String,
      default: ""
    },
    showHover: {
      type: Boolean,
      default: false
    },
    background: {
      type: String,
      default: "#f5f5f5"
    },
    width: {
      type: Number,
      default: 0
    },
    isImageStyle: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(props, context) {
    var content = ref("");

    var _editChange = editChange(context),
        edit = _editChange.edit,
        opacityChange = _editChange.opacityChange,
        clickHandle = _editChange.clickHandle,
        keyDownHandle = _editChange.keyDownHandle;

    var _modalChange = modalChange(content, edit),
        visible = _modalChange.visible,
        sureHandle = _modalChange.sureHandle,
        moreHandle = _modalChange.moreHandle;

    watch(function () {
      return props.html;
    }, function (val) {
      content.value = val;
    }, {
      immediate: true
    });
    watch(edit, function (val) {
      context.emit("focusChange", val, edit);
    });
    watch(content, function (val) {
      context.emit("update:html", val);
    });

    var onClickOutsides = function onClickOutsides(e) {
      opacityChange.value = false;
      edit.value = false;
    };

    return {
      content: content,
      edit: edit,
      opacityChange: opacityChange,
      visible: visible,
      sureHandle: sureHandle,
      clickHandle: clickHandle,
      moreHandle: moreHandle,
      keyDownHandle: keyDownHandle,
      onClickOutsides: onClickOutsides
    };
  }
};
/**
 * 富文本事件处理
 */

function editChange(context) {
  var edit = ref(false);
  var opacityChange = ref(false);

  var clickHandle = function clickHandle() {
    edit.value = true;
    setTimeout(function () {
      opacityChange.value = true;
    }, 100);
  }; // 富文本回车事件


  var keyDownHandle = function keyDownHandle() {
    // opacityChange.value = false;
    // edit.value = false;
    context.emit("keyDown");
  };

  return {
    edit: edit,
    opacityChange: opacityChange,
    clickHandle: clickHandle,
    keyDownHandle: keyDownHandle
  };
}
/**
 * 更多弹框内容处理
 */


function modalChange(content, edit) {
  var visible = ref(false); // modal确认回调

  var sureHandle = function sureHandle() {
    visible.value = false;
    edit.value = false;
  }; // 富文本更多按钮打开弹框


  var moreHandle = function moreHandle(val) {
    visible.value = true;
    content.value = val;
  };

  return {
    visible: visible,
    sureHandle: sureHandle,
    moreHandle: moreHandle
  };
}
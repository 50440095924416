import { defineComponent, reactive, ref } from 'vue';
import { renameTemplate } from '@/api/template-market';
export default defineComponent({
  props: {
    sn: {
      type: String,
      default: ''
    }
  },
  emits: ['cancel', 'update'],
  setup: function setup(props, context) {
    var formRef = ref();
    var ruleForm = reactive({
      title: ''
    });
    var rules = {
      title: [{
        required: true,
        message: '请输入模版名称',
        trigger: 'blur'
      }, {
        min: 1,
        max: 30,
        message: '字数超过限制',
        trigger: 'blur'
      }]
    };

    var onSubmit = function onSubmit() {
      formRef.value.validate().then(function () {
        renameTemplate(props.sn, ruleForm).then(function (res) {
          context.emit('update');
          context.emit('cancel');
        });
      }).catch(function (error) {
        return false;
      });
    };

    return {
      onSubmit: onSubmit,
      formRef: formRef,
      rules: rules,
      ruleForm: ruleForm
    };
  }
});
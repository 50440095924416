import "core-js/modules/es.function.name.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_a_menu_item = _resolveComponent("a-menu-item");

  var _component_a_menu = _resolveComponent("a-menu");

  return _openBlock(), _createBlock(_component_a_menu, null, {
    default: _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.parsedSurveyActions, function (item) {
        return _openBlock(), _createBlock(_component_a_menu_item, {
          class: "menuItem",
          key: item.action,
          onClick: function onClick($event) {
            return _ctx.handleAction(item);
          }
        }, {
          default: _withCtx(function () {
            return [_createTextVNode(_toDisplayString(item.name), 1)];
          }),
          _: 2
        }, 1032, ["onClick"]);
      }), 128))];
    }),
    _: 1
  });
}
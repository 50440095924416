import _objectSpread from "/root/workspace/survey-java-web_GWKp/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { defineComponent, ref } from "vue"; // import { SearchOutlined } from '@ant-design/icons-vue';

import ScreenBox from "./TempSreenBox";
export default defineComponent({
  components: {
    ScreenBox: ScreenBox //  SearchOutlined

  },
  setup: function setup(props, context) {
    var title = ref("");
    var visible = ref(false);
    var searchForm = ref({});

    function update(val) {
      searchForm.value = val;
      context.emit("update", _objectSpread(_objectSpread({}, val), {}, {
        project_name: title.value
      }));
      visible.value = false;
    }

    function onSearch() {
      context.emit("update", _objectSpread(_objectSpread({}, searchForm.value), {}, {
        project_name: title.value
      }));
    }

    return {
      update: update,
      onSearch: onSearch,
      searchForm: searchForm,
      title: title,
      visible: visible
    };
  }
});
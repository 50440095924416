import { useRouter, useRoute } from "vue-router";
import { ref, computed } from "vue";
import Avatar from "@/views/Home/components/Avatar.vue";
export default {
  components: {
    Avatar: Avatar
  },
  setup: function setup(props, content) {
    var router = useRouter();
    var route = useRoute();
    var project_name = ref(computed(function () {
      return route.path.indexOf("user-center") >= 0 ? "个人主页" : "团队成员";
    }));

    var toBack = function toBack() {
      router.back();
    };

    return {
      project_name: project_name,
      toBack: toBack
    };
  }
};
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-f4b9767e"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "header"
};
var _hoisted_2 = {
  class: "left"
};
var _hoisted_3 = {
  class: "leftt"
};
var _hoisted_4 = {
  class: "question-name"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_Avatar = _resolveComponent("Avatar");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("i", {
    class: "iconfont icon-tuichuyulan",
    onClick: _cache[0] || (_cache[0] = function () {
      return $setup.toBack && $setup.toBack.apply($setup, arguments);
    })
  }), _createElementVNode("div", _hoisted_4, _toDisplayString($setup.project_name), 1)])]), _createVNode(_component_Avatar)]);
}
import "core-js/modules/es.number.constructor.js";
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    percent: {
      type: Number,
      default: 0
    },
    color: {
      type: String,
      default: "#fff"
    }
  }
});
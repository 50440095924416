import request from "@/utils/request";

/* 获取分组列表 */
export function getGroupList(params) {
  return request({
    method: "GET",
    url: '/console/survey_groups',
    params,
  });
}
/* 新增分组 */
export function postGroup(data) {
  return request({
    method: "POST",
    url: '/console/survey_groups',
    data,
  });
}
/* 删除分组 */
export function deleteGroup(data) {
  return request({
    method: "delete",
    url: `/console/survey_groups/${data.id}`,
    data: {
      is_all: data.is_all
    }
  });
}
/* 重命名分组 */
export function renewGroupName(data) {
  return request({
    method: "patch",
    url: `/console/survey_groups/${data.id}/title`,
    params: {
      title: data.title
    }
  });
}

/* 问卷列表 */
export function getSurveyList(params) {
  return request({
    method: "get",
    url: '/console/surveys',
    params,
  });
}
/* 新增问卷word */
export function postSurveyWordImportant(data) {
  return request({
    method: "POST",
    url: '/console/survey_word_import',
    data,
  });
}
/* 新增问卷 */
export function postSurvey(data) {
  return request({
    method: "POST",
    url: '/console/surveys',
    data,
  });
}
/* 删除问卷 */
export function deleteSurvey(data) {
  return request({
    method: "delete",
    url: `/console/surveys/${data.sn}`,
  });
}
/* 移动问卷分组 */
export function moveSurvey(data) {
  return request({
    method: "PATCH",
    url: `/console/surveys/${data.sn}/group`,
    data: {
      group_id: data.groupIds,
    },
  });
}
/* 复制问卷 */
export function copySurvey(data) {
  return request({
    method: "post",
    url: `/console/surveys/${data.sn}`,
  });
}
/* 结束问卷 */
export function finishSurvey(data) {
  return request({
    method: "patch",
    url: `/console/surveys/${data.sn}/finish`,
  });
}
/* 重命名问卷 */
export function renewSurvey(data) {
  return request({
    method: "PATCH",
    url: `/console/surveys/${data.sn}/project_name`,
    data: {
      project_name: data.project_name
    },
  });
}
/* 用户列表 */
export function getUserList(params) {
  return request({
    method: "get",
    url: '/console/users',
    params,
  });
}

/* 修改问卷状态 */
export function setSurveyStatus(params) {
  return request({
    url: `/console/surveys/${params.sn}/status`,
    method: "patch",
    data:params
  });
}
/* 标签列表 */
export function getTagsList(params) {
  return request({
    method: "GET",
    url: '/console/survey_tags',
    params,
  });
}
/* 新增标签 */
export function postTags(data) {
  return request({
    method: "POST",
    url: '/console/survey_tags',
    data,
  });
}
/* 删除标签 */
export function deleteTags(id) {
  return request({
    method: "delete",
    url: `/console/survey_tags/${id}`,
  });
}
/* 修改标签 */
export function setSurveyTags(data) {
  return request({
    url: `/console/survey_tags/${data.id}`,
    method: "patch",
    data
  });
}

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, withModifiers as _withModifiers, createVNode as _createVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-3f5e7fa9"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = ["innerHTML"];
var _hoisted_2 = {
  key: 1,
  class: "ques-tiny-text-no"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_tinymce = _resolveComponent("tinymce");

  var _component_a_modal = _resolveComponent("a-modal");

  var _directive_click_outside = _resolveDirective("click-outside");

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: "ques-tiny",
    style: _normalizeStyle({
      background: $setup.edit ? $props.background : '',
      width: $props.width > 0 ? "".concat($props.width, "px") : ''
    }),
    onMouseup: _cache[4] || (_cache[4] = function () {
      return $setup.clickHandle && $setup.clickHandle.apply($setup, arguments);
    })
  }, [$setup.edit ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "ques-tiny-mce",
    style: _normalizeStyle({
      opacity: $setup.opacityChange ? 1 : 0
    })
  }, [!$setup.visible ? (_openBlock(), _createBlock(_component_tinymce, {
    key: 0,
    editorData: $setup.content,
    "onUpdate:editorData": _cache[0] || (_cache[0] = function ($event) {
      return $setup.content = $event;
    }),
    show: $setup.edit,
    preventKeyDown: $props.preventKeyDown,
    curtailMinHeight: $props.minHeight,
    placeholder: $props.placeholder,
    isSelectAll: true,
    background: $props.background,
    onMore: $setup.moreHandle,
    isImageStyle: $props.isImageStyle,
    onKeyDown: $setup.keyDownHandle
  }, null, 8, ["editorData", "show", "preventKeyDown", "curtailMinHeight", "placeholder", "background", "onMore", "isImageStyle", "onKeyDown"])) : _createCommentVNode("", true)], 4)) : _createCommentVNode("", true), _withDirectives(_createElementVNode("div", {
    class: _normalizeClass(["flex-align", {
      'ques-tiny-after': $props.hasAfter
    }]),
    style: _normalizeStyle({
      minHeight: "".concat($props.minHeight, "px")
    })
  }, [$setup.content ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: _normalizeClass(["ques-tiny-text", {
      'ques-tiny-text-hover': $props.showHover
    }]),
    style: {
      "word-break": "break-word!important"
    },
    innerHTML: $setup.content
  }, null, 10, _hoisted_1)) : _createCommentVNode("", true), $props.defaultStem && !$setup.content ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString($props.placeholder), 1)) : _createCommentVNode("", true)], 6), [[_vShow, !$setup.edit]]), $setup.edit ? (_openBlock(), _createElementBlock("div", {
    key: 1,
    onClick: _cache[1] || (_cache[1] = _withModifiers(function () {}, ["stop"]))
  }, [_renderSlot(_ctx.$slots, "content", {}, undefined, true)])) : _createCommentVNode("", true), _createVNode(_component_a_modal, {
    visible: $setup.visible,
    "onUpdate:visible": _cache[3] || (_cache[3] = function ($event) {
      return $setup.visible = $event;
    }),
    width: "1000",
    destroyOnClose: false,
    centered: true,
    maskClosable: false,
    title: "富文本编辑",
    onOk: $setup.sureHandle
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_tinymce, {
        editorData: $setup.content,
        "onUpdate:editorData": _cache[2] || (_cache[2] = function ($event) {
          return $setup.content = $event;
        }),
        openMoreDialog: $setup.visible,
        curtail: false,
        isImageStyle: $props.isImageStyle
      }, null, 8, ["editorData", "openMoreDialog", "isImageStyle"])];
    }),
    _: 1
  }, 8, ["visible", "onOk"])], 36)), [[_directive_click_outside, $setup.onClickOutsides]]);
}
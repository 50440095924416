import request from '@/utils/request'
/**
 * 修改用户名
 */
export function update_nickname(data) {
  console.log('update_nickname', data.user_id);
  return request({
    url: `/console/users/${data.user_id}/nickname`,
    method: 'PATCH',
    data
  })
}


export function sms_codes(data) {
  return request({
    url: `/system/sms_codes`,
    method: 'POST',
    data
  })
}

/**
 * 修改手机号
 */
export function update_phone (data) {
  return request({
    url: `/console/users/phones`,
    method: 'PATCH',
    data
  })
}

/**
 * 修改手机号验证
 */
export function update_phone_check(data) {
  return request({
    url: `/console/authorizations/update_phone_check`,
    method: 'PATCH',
    data
  })
}


// 更换密码-验证
export function check_reset_password(data) {
  return request({
    url: `/console/authorizations/check_reset_password`,
    method: 'GET',
    params: data
  })
}



/**
 * 更换密码
 */
export function update_password(data) {
  return request({
    url: `/console/authorizations/reset_password`,
    method: 'PATCH',
    data
  })
}


/**
 * 修改头像
 */
export function update_avatar (data) {
  return request({
    url: `/console/users/avatars`,
    method: 'PATCH',
    data
  })
}

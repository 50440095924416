import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-3f49f1c5"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "layout"
};
var _hoisted_2 = {
  class: "left",
  ref: "left"
};
var _hoisted_3 = {
  class: "right",
  ref: "right"
};
export function render(_ctx, _cache) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "left", {}, undefined, true)], 512), _createElementVNode("div", _hoisted_3, [_renderSlot(_ctx.$slots, "right", {}, undefined, true)], 512)]);
}
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.string.fixed.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_VueCropper = _resolveComponent("VueCropper", true);

  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", {
    class: "cropper-content",
    style: _normalizeStyle("width:".concat($setup.option.width, "px;height:").concat($setup.option.height, "px;"))
  }, [_createVNode(_component_VueCropper, {
    ref: "cropper",
    img: $setup.baseUrl,
    "output-size": $setup.option.size,
    "output-type": $setup.option.outputType,
    canScale: $setup.option.canScale,
    full: $setup.option.full,
    info: true,
    fixed: $setup.option.fixed,
    "fixed-number": $props.fixedNumber,
    "can-move": $setup.option.canMove,
    "can-move-box": $setup.option.canMoveBox,
    "fixed-box": $setup.option.fixedBox,
    original: $setup.option.original,
    "auto-crop": $setup.option.autoCrop,
    "auto-crop-width": $setup.option.autoCropWidth,
    "auto-crop-height": $setup.option.autoCropHeight,
    "center-box": $setup.option.centerBox,
    onRealTime: $setup.realTime,
    high: $setup.option.high,
    onImgLoad: _ctx.imgLoad,
    mode: "cover",
    "max-img-size": $setup.option.max
  }, null, 8, ["img", "output-size", "output-type", "canScale", "full", "fixed", "fixed-number", "can-move", "can-move-box", "fixed-box", "original", "auto-crop", "auto-crop-width", "auto-crop-height", "center-box", "onRealTime", "high", "onImgLoad", "max-img-size"])], 4)]);
}
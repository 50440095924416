import _defineProperty from "/root/workspace/survey-java-web_GWKp/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.string.fixed.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.typed-array.uint8-array.js";
import "core-js/modules/es.typed-array.at.js";
import "core-js/modules/es.typed-array.copy-within.js";
import "core-js/modules/es.typed-array.every.js";
import "core-js/modules/es.typed-array.fill.js";
import "core-js/modules/es.typed-array.filter.js";
import "core-js/modules/es.typed-array.find.js";
import "core-js/modules/es.typed-array.find-index.js";
import "core-js/modules/es.typed-array.for-each.js";
import "core-js/modules/es.typed-array.includes.js";
import "core-js/modules/es.typed-array.index-of.js";
import "core-js/modules/es.typed-array.iterator.js";
import "core-js/modules/es.typed-array.join.js";
import "core-js/modules/es.typed-array.last-index-of.js";
import "core-js/modules/es.typed-array.map.js";
import "core-js/modules/es.typed-array.reduce.js";
import "core-js/modules/es.typed-array.reduce-right.js";
import "core-js/modules/es.typed-array.reverse.js";
import "core-js/modules/es.typed-array.set.js";
import "core-js/modules/es.typed-array.slice.js";
import "core-js/modules/es.typed-array.some.js";
import "core-js/modules/es.typed-array.sort.js";
import "core-js/modules/es.typed-array.subarray.js";
import "core-js/modules/es.typed-array.to-locale-string.js";
import "core-js/modules/es.typed-array.to-string.js";
import "vue-cropper/dist/index.css";
import { watch } from "vue";
import { VueCropper } from "vue-cropper";
import { onMounted, ref } from "@vue/runtime-core";
export default {
  components: {
    VueCropper: VueCropper
  },
  props: {
    url: {
      type: String,
      default: function _default() {
        return "";
      }
    },
    autoCropWidth: {
      type: Number,
      default: function _default() {
        return 176;
      }
    },
    autoCropHeight: {
      type: Number,
      default: function _default() {
        return 176;
      }
    },
    fixedBox: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    fixed: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    fixedNumber: {
      type: Array,
      default: function _default() {
        return [1, 1];
      }
    },
    canScale: {
      type: Boolean,
      default: function _default() {
        return true;
      }
    },
    canMoveBox: {
      type: Boolean,
      default: function _default() {
        return true;
      }
    },
    canMove: {
      type: Boolean,
      default: function _default() {
        return true;
      }
    }
  },
  setup: function setup(props, content) {
    var _ref;

    var cropper = ref(null);
    var imageUrl = ref(props.url);
    var autoCropWidth = props.autoCropWidth > 500 ? 500 : props.autoCropWidth;
    var autoCropHeight = props.autoCropHeight > 500 ? 500 : props.autoCropHeight;

    if (props.autoCropWidth > 500) {
      autoCropHeight = props.autoCropHeight * 500 / props.autoCropWidth;
    } else if (props.autoCropHeight > 500) {
      autoCropWidth = props.autoCropWidth * 500 / props.autoCropHeight;
    }

    var option = ref((_ref = {
      size: 1,
      full: true,
      outputType: "png",
      canMove: true,
      fixedBox: props.fixedBox || false,
      original: true,
      canMoveBox: true
    }, _defineProperty(_ref, "canMove", props.canMove), _defineProperty(_ref, "canScale", props.canScale), _defineProperty(_ref, "autoCrop", true), _defineProperty(_ref, "autoCropWidth", autoCropWidth), _defineProperty(_ref, "autoCropHeight", autoCropHeight), _defineProperty(_ref, "centerBox", false), _defineProperty(_ref, "high", true), _defineProperty(_ref, "max", 99999), _defineProperty(_ref, "fixed", props.fixed || false), _defineProperty(_ref, "width", 500), _defineProperty(_ref, "height", 500), _defineProperty(_ref, "fixedNumber", props.fixedNumber || []), _ref));
    var image = new Image(); //解决跨域问题

    image.crossOrigin = "*";
    image.src = imageUrl.value + "?v=" + Math.random();
    var baseUrl = ref("");

    var base64ToFile = function base64ToFile(dataUrl, name) {
      var arr = dataUrl.split(","),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], name + ".jpg", {
        type: "image/jpg"
      });
    };

    onMounted(function () {
      console.log(cropper, "ref");
      console.log(image);

      image.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = 500;
        canvas.height = 500 / image.width * image.height;
        image.width = 500;
        image.height = canvas.height;
        var context = canvas.getContext("2d");
        image.fillStyle = "rgba(255, 255, 255, 0)";
        context.fillStyle = "rgba(255, 255, 255, 0)"; // 在canvas绘制前填充白色背景

        context.fillStyle = "#fff";
        context.fillRect(0, 0, canvas.width, canvas.height);
        context.drawImage(image, 0, 0, image.width, image.height);
        option.value.width = image.width;
        option.value.height = image.height;
        var quality = 1; //这里的dataurl就是base64类型

        var dataURL = canvas.toDataURL("image/jpeg", quality); //使用toDataUrl将图片转换成jpeg的格式,不要把图片压缩成png，因为压缩成png后base64的字符串可能比不转换前的长！

        baseUrl.value = dataURL;
      };
    });

    var getData = function getData() {
      cropper.value.getCropData(function (data) {
        content.emit("change", {
          file: {
            file: base64ToFile(data, "".concat(new Date().getTime()))
          },
          fileBase64: data
        });
      });
    };

    var realTime = function realTime(data) {
      // console.log(data,'data123465789');
      content.emit("realInfo", data);
    };

    return {
      baseUrl: baseUrl,
      option: option,
      cropper: cropper,
      getData: getData,
      realTime: realTime
    };
  }
};
import "core-js/modules/es.array.concat.js";
import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-44dcb330"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "progress"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: "bar",
    style: _normalizeStyle("width: ".concat(_ctx.percent, "%;background-color: ").concat(_ctx.color))
  }, null, 4)]);
}
import { useStore } from "vuex";
import Layout from "./components/Layout";
import GroupList from "./fragment/GroupList";
import TableList from "./fragment/TableList";
import { defineComponent, ref, watch, onMounted, nextTick } from "vue";
var page;
export default defineComponent({
  name: "Index",
  components: {
    Layout: Layout,
    TableList: TableList
  },
  beforeRouteEnter: function beforeRouteEnter(to, from) {
    page = from.query.page ? from.query.page : "";
  },
  setup: function setup(props, context) {
    var store = useStore();
    var pageIndex = ref(page);
    var groupId = ref(-1);
    var groupList = ref([]);

    function changeGroup(id) {
      groupId.value = id;
      table.value.page = 1;
    }

    var rightheight = ref();

    var tableheight = function tableheight(_tableheight) {
      nextTick(function () {
        context.emit('tableheight', table.value.$el.offsetHeight);
      });
      rightheight.value = _tableheight;
    };

    var list = ref();
    var table = ref();
    watch(function () {
      return store.state.common.initHomeData;
    }, function () {
      getAuthorizationsRoles();
      console.log("切团队啦");
    }); // 获取当前权限显示

    var getAuthorizationsRoles = function getAuthorizationsRoles() {
      // try {
      //   const subData = {flag:2}
      //   let data = await store.dispatch('teamManager/getAuthorizationsRoles',subData)
      //   window.localStorage.authorizationsRoles = JSON.stringify(data)
      // list.value.getGroups();
      table.value.getSurveys(); // } catch (error) {
      //   console.error(error);
      // }
    };

    onMounted(function () {
      getAuthorizationsRoles();
    }); // 获取最新分组列表

    var newGroups = function newGroups() {// console.log('value',132467896548732154654);
      // list.value.getGroups();
    };

    return {
      tableheight: tableheight,
      groupId: groupId,
      changeGroup: changeGroup,
      list: list,
      table: table,
      rightheight: rightheight,
      groupList: groupList,
      pageIndex: pageIndex,
      newGroups: newGroups
    };
  }
});
import request from '@/utils/request'

// 复制链接
export function post_team_role(data) {
  return request({
    url: `/console/team_roles/code`,
    method: 'POST',
    data
  })
}

// 获取权限列表
export function get_team_role_list(data) {
  return request({
    url: `/console/team_roles/role_list`,
    method: 'GET',
    data
  })
}

// 添加成员
export function post_team_users(data) {
  return request({
    url: `/console/team_users/${data.user_id}`,
    method: 'POST',
    data
  })
}

// 添加成员
export function get_team_users(data) {
  return request({
    url: `/console/team_users`,
    method: 'GET',
    params: data
  })
}
// 删除
export function del_team_user_groups(data) {
  return request({
    url: `/console/team_users/${data.user_id}/team_user_groups`,
    method: 'DELETE',
    data
  })
}
/**
 * 获取分组
 */
export function get_team_user_groups(data) {
  return request({
    url: `/console/team_user_groups`,
    method: 'GET',
    params: data
  })
}

/**
 * 创建分组
 */
export function post_team_user_groups(data) {
  return request({
    url: `/console/team_user_groups`,
    method: 'POST',
    data
  })
}
/**
 * 删除分组
 */
export function delete_team_user_groups(params) {
  return request({
    url: `/console/team_user_groups/${params.id}`,
    method: 'DELETE',
    params
  })
}

/**
 * 重命名
 */
export function patch_team_user_groups(data) {
  return request({
    url: `/console/team_user_groups/${data.id}/title`,
    method: 'PATCH',
    data
  })
}
/**
 * 变更分组
 */
export function patch_team_user_groups_user(data) {
  return request({
    url: `/console/team_users/${data.user_id}/team_user_groups`,
    method: 'PATCH',
    data
  })
}

/**
 *  权限列表
 */
export function auth_index(params) {
  return request({
    url: `/console/auth_index/${params.user_id}`,
    method: 'GET',
    params
  })
}
/**
 *  编辑权限
 */
export function surveys(data) {
  return request({
    url: `/console/users/${data.user_id}/surveys`,
    method: 'PATCH',
    data
  })
}

import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-2863e5d2"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "pro-layout"
};
var _hoisted_2 = {
  class: "content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_Avatar = _resolveComponent("Avatar");

  var _component_router_view = _resolveComponent("router-view");

  var _component_a_spin = _resolveComponent("a-spin");

  return _openBlock(), _createBlock(_component_a_spin, {
    tip: "Loading...",
    spinning: _ctx.spinningLoading
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createVNode(_component_Avatar, {
        onResetAll: _ctx.resetAll
      }, null, 8, ["onResetAll"]), _createElementVNode("div", _hoisted_2, [!_ctx.spinningLoading ? (_openBlock(), _createBlock(_component_router_view, {
        key: 0
      })) : _createCommentVNode("", true)])])];
    }),
    _: 1
  }, 8, ["spinning"]);
}